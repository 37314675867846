import React from 'react'

import EditableContent from '../../../../utils/editable-content'

const MenuTitle = ({ menu }) => {
    return (
        <div className="menu-block menu-title-block">
            <div className="block-details">
               Title Block
            </div>
            <EditableContent
                dataObj={menu}
                field="title"
                endpoint="chefs/chef-menus"
                className="menu-title" />
            <EditableContent
                dataObj={menu}
                field="subtitle"
                endpoint="chefs/chef-menus"
                className="menu-subtitle"
                placeholder="Optional Menu Subtitle" />
        </div>
    )
}

export default MenuTitle

