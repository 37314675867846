import React from 'react'
import { connect } from 'react-redux'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import { closeDrawer } from '../../../reducers/ui-drawer'
import { callApi } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

const EditBio = ({ dispatch, chef }) => {
    const submitFX = (formData, formId) => {
        console.log(formData)
        dispatch(callApi(apiResourceEndpoint('chefs', 'UPDATE', chef.id), formData, formId))
    }

    const successFX = () => {
        dispatch(closeDrawer())
    }
    return (
        <div>
            <h3>Edit Bio</h3>
            <SHSForm submitFX={submitFX} successFX={successFX} resetForm={true} showCancel={true} cancelAction={() => dispatch(closeDrawer())}>
                <InputElement
                    type="textarea"
                    id="shortBio"
                    label={null}
                    default={chef.shortBio} />
            </SHSForm>
        </div>
    )
}

export default connect()(EditBio)
