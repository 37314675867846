import React from 'react'

import { Link } from 'react-router-dom'
import { routes } from '../../constants/routes'

class Home extends React.Component {
    state = {}

    render = () => {
        return (
            <div id="home">
                <Link to={routes.authLogin.path} className="btn">Proceed to Login</Link>
            </div>
        )
    }
}

export default Home
