import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../utils/with-router-props'
import { NavLink, Routes, Route } from 'react-router-dom'

import { fetchApiDataIfNeeded } from '../../actions/api'
import { apiResourceEndpoint, buildRoutePath, nestedRoutePath, routes } from '../../constants/routes'
import '../../assets/scss/chef-portal.scss'

import { closeDrawer } from '../../reducers/ui-drawer'

import ChefPortalNav from '../layout/chef-portal-nav'
import ChefPortalOverview from './overview/index'
import ChefProfileIndex from './profile/index'
import ChefPortalAvailability from './availability/index'
import ChefPortalPhotosList from './photos/index'
import ChefPortalMenusIndex from './menus/index'
import ChefPortalTickets from './tickets/index'
import ChefPortalDocuments from './documents/index'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditPhoto from './photos/edit-photo'
import CreatePhoto from './photos/create-photo'
import EditMainProfile from './profile/edit-main-profile'
import EditBio from './profile/edit-bio'
import ProfilePhoto from './profile/profile-photo'
import EditCuisines from './profile/edit-cuisines'
import EditServiceStyles from './profile/edit-service-styles'

const mapStateToProps = (state, ownProps) => {
    const user = state.user
    return {
        chef: state.chefs.find(ac => ac.id === user?.apiUserId),
        uiDrawer: state.uiDrawer
    }
}

const ChefPortalIndex = ({ dispatch, params, chef, uiDrawer, location }) => {
    const [navbarOpen, setNavbarOpen] = useState(false)

    const onClickNav = () => {
        setNavbarOpen(!navbarOpen)
    }

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs', 'LIST')))
    }, [dispatch])

    useEffect(() => {
        dispatch(closeDrawer())
    }, [dispatch, location.pathname])

    if (!chef) {
        return (
            <h1>Loading...</h1>
        )
    }

    const renderDrawerContent = () => {
        switch (uiDrawer.category) {
        case 'EDIT_PHOTO':
            return <EditPhoto photoId={uiDrawer.objId} />
        case 'ADD_PHOTO':
            return <CreatePhoto chef={chef} />
        case 'EDIT_MAIN_PROFILE':
            return <EditMainProfile chef={chef} />
        case 'EDIT_BIO':
            return <EditBio chef={chef} />
        case 'PROFILE_PHOTO':
            return <ProfilePhoto chef={chef} />
        case 'EDIT_CUISINES':
            return <EditCuisines chef={chef} />
        case 'EDIT_SERVICE':
            return <EditServiceStyles chef={chef} />
        default:
            return null
        }
    }

    return (
        <>
            <ChefPortalNav chef={chef} />
            <div id="chef-wrapper" className={`${uiDrawer.isOpen ? 'drawer-open' : 'drawer-closed'}`}>
                <div id="chef-layout">
                    <div className="portal-header-nav">
                        <div className={`${navbarOpen ? 'nav-dropdown open' : 'nav-dropdown'}`}>
                            <button className="dropdown-btn" onClick={onClickNav}>Navigate <FontAwesomeIcon icon="caret-down" /></button>
                            <div className="dropdown-content" onClick={onClickNav}>
                                <NavLink to={buildRoutePath(routes.chefPortalOverview.path, { chefId: chef.id })}><FontAwesomeIcon icon="list" /> Overview</NavLink>
                                <NavLink to={buildRoutePath(routes.chefPortalProfile.path, { chefId: chef.id })}>
                                    <FontAwesomeIcon icon="user-ninja" /> Profile
                                </NavLink>
                                <NavLink to={buildRoutePath(routes.chefPortalMenusIndex.path, { chefId: chef.id })}>
                                    <FontAwesomeIcon icon="bowl-rice" /> Menus
                                </NavLink>
                                {location.pathname.indexOf('/chefs/menus') > -1 &&
                                    <>
                                        <NavLink to={buildRoutePath(routes.chefPortalMenusIndex.path, { chefId: chef.id })}>
                                            &nbsp; &nbsp; <FontAwesomeIcon icon="clipboard-list" /> Menus
                                        </NavLink>
                                        <NavLink to={buildRoutePath(routes.chefPortalFoodItemsIndex.path, { chefId: chef.id })}>
                                            &nbsp; &nbsp; <FontAwesomeIcon icon="carrot" /> Food Items
                                        </NavLink>
                                    </>
                                }
                                <NavLink to={buildRoutePath(routes.chefPortalAvailability.path, { chefId: chef.id })}>
                                    <FontAwesomeIcon icon={['far', 'calendar-alt']} />  Availability
                                </NavLink>
                                <NavLink to={buildRoutePath(routes.chefPortalPhotos.path, { chefId: chef.id })}>
                                    <FontAwesomeIcon icon="images" /> Photos
                                </NavLink>
                                <NavLink to={routes.authLogout.path}>
                                    <FontAwesomeIcon icon="sign-out" /> Logout
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-wrapper">
                        <div className="sidebar">
                            <div className="side-nav">
                                <NavLink to={buildRoutePath(routes.chefPortalOverview.path, { chefId: chef.id })}>
                                    <FontAwesomeIcon icon="list" /> Overview
                                </NavLink>
                                {/* <NavLink to={buildRoutePath(routes.chefPortalTickets.path, { chefId: chef.id })}>
                                    <FontAwesomeIcon icon="ticket" /> Tickets
                                </NavLink> */}
                                <NavLink to={buildRoutePath(routes.chefPortalProfile.path, { chefId: chef.id })}>
                                    <FontAwesomeIcon icon="user-ninja" /> Profile
                                </NavLink>
                                <NavLink to={buildRoutePath(routes.chefPortalMenusIndex.path, { chefId: chef.id })}>
                                    <FontAwesomeIcon icon="bowl-rice" /> Menus
                                </NavLink>
                                {location.pathname.indexOf('/chefs/menus') > -1 &&
                                    <>
                                        <NavLink to={buildRoutePath(routes.chefPortalMenusIndex.path, { chefId: chef.id })}>
                                            &nbsp; &nbsp; <FontAwesomeIcon icon="clipboard-list" /> Menus
                                        </NavLink>
                                        <NavLink to={buildRoutePath(routes.chefPortalFoodItemsIndex.path, { chefId: chef.id })}>
                                            &nbsp; &nbsp; <FontAwesomeIcon icon="carrot" /> Food Items
                                        </NavLink>
                                    </>
                                }
                                <NavLink to={buildRoutePath(routes.chefPortalAvailability.path, { chefId: chef.id })}>
                                    <FontAwesomeIcon icon={['far', 'calendar-alt']} /> Availability
                                </NavLink>
                                <NavLink to={buildRoutePath(routes.chefPortalPhotos.path, { chefId: chef.id })}>
                                    <FontAwesomeIcon icon="images" /> Photos
                                </NavLink>
                                {/* <NavLink to={buildRoutePath(routes.chefPortalDocuments.path, { chefId: chef.id })}>
                                    <FontAwesomeIcon icon="file" /> Documents
                                </NavLink> */}
                                <br />
                                <NavLink to={routes.authLogout.path}>
                                    <FontAwesomeIcon icon="sign-out" /> Logout
                                </NavLink>
                            </div>
                        </div>
                        <div className="main">
                            <Routes>
                                <Route path={nestedRoutePath(routes.chefPortalIndex.path, routes.chefPortalOverview.path, true)} element={<ChefPortalOverview chef={chef} />} />
                                <Route path={nestedRoutePath(routes.chefPortalIndex.path, routes.chefPortalAvailability.path, true)} element={<ChefPortalAvailability chef={chef} />} />
                                <Route path={nestedRoutePath(routes.chefPortalIndex.path, routes.chefPortalPhotos.path, true)} element={<ChefPortalPhotosList chef={chef} />} />
                                <Route path={nestedRoutePath(routes.chefPortalIndex.path, routes.chefPortalMenusIndex.path, true)} element={<ChefPortalMenusIndex chef={chef} />} />
                                <Route path={nestedRoutePath(routes.chefPortalIndex.path, routes.chefPortalProfile.path, true)} element={<ChefProfileIndex chef={chef} />} />
                                <Route path={nestedRoutePath(routes.chefPortalIndex.path, routes.chefPortalTickets.path, true)} element={<ChefPortalTickets chef={chef} />} />
                                <Route path={nestedRoutePath(routes.chefPortalIndex.path, routes.chefPortalDocuments.path, true)} element={<ChefPortalDocuments chef={chef} />} />
                                <Route index element={<ChefPortalOverview chef={chef} />} />
                            </Routes>
                        </div>
                    </div>
                </div>
                <div id="chef-drawer" className={`${uiDrawer.isOpen ? 'drawer-open' : 'drawer-closed'}`}>
                    <div className="closer">
                        <FontAwesomeIcon icon="times" onClick={() => dispatch(closeDrawer())} />
                    </div>
                    <div className="drawer-content">
                        {renderDrawerContent()}
                    </div>
                </div>
            </div>
        </>

    )
}

export default withRouterProps(connect(mapStateToProps)(ChefPortalIndex))
