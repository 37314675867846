import React from 'react'

import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'

const ContactInfo = () => {
    const submitFX = (formData, formId) => {
        // console.log('i am here with ' + formData)
    }

    const successFX = () => {

    }

    return (
        <>
            <div className="full-page-bg-img" style={{ backgroundImage: 'url("/img/bg/place-setting.jpg")' }} />
            <div className="form-holder">
                <div className="event-form">
                    <div className="form-header">
                        <img className="mb-5" src="/img/icons/tivity-logo.png" alt="logo" width="100px" />
                        <h1>Tell us a little about you...</h1>
                        <h4> Let us know who you are and the best way to reach you.</h4>
                    </div>
                    <SHSForm submitFX={submitFX} successFX={successFX} buttonLabel="Send Request" formClass="center">
                        <InputElement
                            type="text"
                            id="firstName"
                            label="First Name"
                            extraClass="flex-50"
                            placeholder="First Name"
                            required />
                        <InputElement
                            type="text"
                            id="lastName"
                            label="Last Name"
                            extraClass="flex-50"
                            placeholder="Last Name"
                            required />
                        <InputElement
                            type="tel"
                            id="phone"
                            label="Phone"
                            extraClass="flex-100"
                            placeholder="Phone"
                            required />
                        <InputElement
                            type="email"
                            id="email"
                            label="Email"
                            extraClass="flex-100"
                            placeholder="Email"
                            required />
                    </SHSForm>
                </div>
            </div>
        </>
    )
}

export default ContactInfo
