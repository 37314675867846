import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouterProps } from '../../../../utils/with-router-props'

import { fetchApiDataIfNeeded } from '../../../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../../../constants/routes'

import { PaginationWrapper } from 'shs-pagination'


const mapStateToProps = (state) => {
    const paginator = state.paginator.chefFoodItems
    let foodItems = state.chefFoodItems
    if (paginator) {
        foodItems = foodItems
            .filter(field => paginator.itemIds.indexOf(field.id) !== -1)
            .sort((a, b) => {
                if (paginator.itemIds.indexOf(a.id) > paginator.itemIds.indexOf(b.id)) {
                    return 1
                }
                return -1
            })
    }
    return { foodItems, paginator }
}

const FoodItemsList = ({ dispatch, foodItems, paginator, navigate }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs/food-items', 'LIST')))
    }, [dispatch])

    if (!foodItems) {
        return null
    }

    const renderFoodItemRow = (foodItem, index) => {
        return (
            <tr key={foodItem.id}>
                <td className="index">{index}.</td>
                <td>
                    <Link to={buildRoutePath(routes.chefPortalFoodItemsView.path, { foodItemId: foodItem.id })} className="main">
                        {foodItem.title}
                    </Link>
                </td>
                <td>{foodItem.description}</td>
            </tr>
        )
    }

    const handlePaginationUpdate = (paginationParams) => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs/food-items', 'LIST'), paginationParams))
    }

    const renderFoodItems = () => {
        if (!paginator) {
            return null
        }
        let allFoodItems = []
        foodItems.forEach((foodItem, idx) => {
            let index = idx + ((paginator.currentPage - 1) * paginator.perPage) + 1
            allFoodItems.push(renderFoodItemRow(foodItem, index))
        })

        const controls = {
            search: { active: true, collapsed: true }
        }

        return (
            <PaginationWrapper
                paginator={paginator}
                controls={controls}
                callbackFX={handlePaginationUpdate}
                countOptions={[2, 5, 10, 20]}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Food Item</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allFoodItems}
                    </tbody>
                </table>
            </PaginationWrapper>
        )
    }

    const renderActionButton = () => {
        return (
            <Link to={routes.chefPortalFoodItemsCreate.path}>
                + Add Food Items
            </Link>
        )
    }

    return (
        <>
            {renderActionButton()}
            {renderFoodItems()}
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(FoodItemsList))
