import React from 'react'
import { connect } from 'react-redux'

import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'
import { registerWithEmailAndPass } from '../../actions/auth'

const Register = (props) => {
    const { dispatch, fireauth } = props

    const submitFX = (formData, formId) => {
        dispatch(registerWithEmailAndPass(fireauth, formData.email, formData.password, formId))
    }

    const successFX = () => {
        // this will be handled by firebase auth
    }

    return (
        <>
            <div className="full-page-bg-img" style={{ backgroundImage: 'url("/img/bg/pizza-salad.jpg")' }} />
            <div className="form-holder">
                <div className="event-form">
                    <div className="form-header">
                        <span className="logo-container">
                            <img src="/img/icons/tivity-logo.png" alt="Tivity Logo" />
                            <h1>register</h1>
                        </span>
                        <p>
                            Use the form below to create an account with Tivity.
                        </p>
                    </div>
                    <SHSForm submitFX={submitFX} successFX={successFX}>
                        <InputElement
                            type="email"
                            id="email"
                            label="Email Address"
                            placeholder="user@example.com"
                            required />
                        <InputElement
                            type="password"
                            id="password"
                            label="Password"
                            placeholder="Something only you know..."
                            required />
                    </SHSForm>
                </div>
            </div>
        </>
    )
}

export default connect()(Register)
