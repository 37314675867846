import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { nestedRoutePath, routes } from '../../constants/routes'

import PublicNav from '../layout/public-nav'

import Home from './home'

import ContactInfo from './contact-info'
import CreateEvent from './create-event'
import Register from './register'
import Login from './login'
import Logout from './logout'
import CreateUser from './create-user'

// import NotFound from './not-found'

const PublicIndex = (props) => {
    const { fireauth } = props

    return (
        <>
            <PublicNav />
            <div id="public-wrapper">
                <Routes>
                    <Route path={nestedRoutePath(routes.publicIndex.path, routes.contactInfo.path)} element={<ContactInfo />} />
                    <Route path={nestedRoutePath(routes.publicIndex.path, routes.createEvent.path)} element={<CreateEvent />} />
                    <Route path={nestedRoutePath(routes.publicIndex.path, routes.authRegister.path)} element={<Register fireauth={fireauth} />} />
                    <Route path={nestedRoutePath(routes.publicIndex.path, routes.authLogin.path)} element={<Login fireauth={fireauth} />} />
                    <Route path={nestedRoutePath(routes.publicIndex.path, routes.createUser.path)} element={<CreateUser />} />
                    <Route path={nestedRoutePath(routes.publicIndex.path, routes.authLogout.path)} element={<Logout fireauth={fireauth} />} />
                    <Route index element={<Home />} />
                    {/* <Route path="*" element={<NotFound />} /> */}
                </Routes>
            </div>
        </>
    )
}

export default PublicIndex
