import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import '../../../assets/scss/photos.scss'
import { openDrawer } from '../../../reducers/ui-drawer'

const selectPhotos = state => state.chefPhotos
const selectChefId = (state, chefId) => chefId

const makeChefPhotos = createSelector(
    [selectPhotos, selectChefId],
    (photos, chefId) => {
        return photos
            .filter(photo => photo.chefId === chefId)
            .sort((a, b) => {
                if (a.order > b.order) {
                    return 1
                }
                return -1
            })
    }
)

const mapStateToProps = (state, ownProps) => {
    return {
        photos: makeChefPhotos(state, state.user?.apiUserId)
        // photos: state.chefPhotos.filter(cp => cp.chefId === state.user?.apiUserId)
    }
}

const ChefPortalPhotosList = ({ dispatch, chef, photos }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs/chef-photos', 'LIST'), { filter: [`chef_id|${chef.id}`] }))
    }, [dispatch, chef.id])

    const renderPhotos = () => {
        let allPhotos = []
        photos.forEach(photo => {
            allPhotos.push(
                <div key={photo.id} className="photo-wrapper">
                    <div className="thumbnail" onClick={() => dispatch(openDrawer({ category: 'EDIT_PHOTO', objId: photo.id }))}>
                        <img src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`api-photos/${photo.photoId}`, 'LIST').url + '?style=md-square'} alt={photo.overrideAlt ? photo.overrideAlt : photo._computed.photoAlt} />
                    </div>
                </div>
            )
        })
        return (
            <div className="photo-rows">
                {allPhotos}
                <div className="add-photo" onClick={() => dispatch(openDrawer({ category: 'ADD_PHOTO', objId: photos.length + 1 }))}>
                    + Add Photo
                </div>
            </div>
        )
    }

    return (
        <div className="box mb-5">
            {renderPhotos()}
        </div>
    )
}

export default connect(mapStateToProps)(ChefPortalPhotosList)
