import React from 'react'
import { connect } from 'react-redux'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import * as MASKS from '../../utils/input-masks'
import { closeDrawer } from '../../../reducers/ui-drawer'
import { callApi } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

const EditMainProfile = ({ dispatch, chef }) => {
    const submitFX = (formData, formId) => {
        dispatch(callApi(apiResourceEndpoint('chefs', 'UPDATE', chef.id), formData, formId))
    }

    const successFX = () => {
        dispatch(closeDrawer())
    }

    return (
        <div>
            <h3>Edit Profile</h3>
            <SHSForm submitFX={submitFX} successFX={successFX} resetForm={true} showCancel={true} cancelAction={() => dispatch(closeDrawer())}>
                <InputElement
                    type="text"
                    id="firstName"
                    label="First Name"
                    default={chef.firstName}
                    required />
                <InputElement
                    type="text"
                    id="lastName"
                    label="Last Name"
                    default={chef.lastName}
                    required />
                <InputElement
                    type="text"
                    id="displayName"
                    label="Display Name"
                    default={chef.displayName}
                    required />
                <InputElement
                    type="email"
                    id="email"
                    label="Email"
                    default={chef.email}
                    required />
                <InputElement
                    type="text"
                    id="phone"
                    label="Phone"
                    isFormatted={true}
                    formatMask={MASKS.phoneMask}
                    default={chef.phone}
                    required />
                <InputElement
                    type="text"
                    id="title"
                    label="Title"
                    default={chef.title} />
                <InputElement
                    type="text"
                    id="pronouns"
                    label="Pronouns"
                    default={chef.pronouns} />
                <InputElement
                    type="text"
                    id="pronounciation"
                    label="Pronounciation"
                    default={chef.pronounciation} />
            </SHSForm>
        </div>
    )
}

export default connect()(EditMainProfile)
