import React from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import { withRouterProps } from './utils/with-router-props'
// lodash
import { isEmpty } from 'lodash'
import { routes } from '../constants/routes'

import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { saveAnonymousAuth } from '../actions/auth'

import PublicIndex from './public/index'
import AppIndex from './app/index'
import SharedIndex from './shared/index'
import AdminIndex from './admin/index'
import AuthRouter from './auth/auth-router'
import ChefPortalIndex from './chef-portal/index'
import PreviewIndex from './preview'

import '../assets/scss/app.scss'

// Font Awesome as needed
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faUserCog, faTimes, faCheck, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { removeAuth, saveAuth } from '../reducers/auth'
import DevTools from './utils/dev-tools'
// library.add(faUserCog, faTimes, faCheck, faCaretDown)
import './utils/icons'

const ROUTER_PAGES = [routes.authLogin.path, routes.router.path, routes.authRegister.path, routes.authPasswordReset.path]

function AppRoute({ children, auth, location }) {
    let isAuthenticated = (!isEmpty(auth) && 'authId' in auth) || false
    return isAuthenticated ? children : <Navigate to={routes.authLogin.path} state={{ from: location }} />
}

function AdminRoute({ children, auth, user, location }) {
    let isAuthenticated = (!isEmpty(auth) && !auth.isAnonymous && 'authId' in auth) || false
    let isAdmin = (!isEmpty(user) && user?._computed?.roleLevel >= 1000) || false
    return isAuthenticated && isAdmin ? children : <Navigate to={routes.authLogin.path} state={{ from: location }} />
}

function ChefRoute({ children, auth, user, location }) {
    let isAuthenticated = (!isEmpty(auth) && !auth.isAnonymous && 'authId' in auth) || false
    let isChef = (!isEmpty(user) && user?._computed?.roleLevel >= 500) || false
    return isAuthenticated && isChef ? children : <Navigate to={routes.authLogin.path} state={{ from: location }} />
}

class App extends React.Component {
    state = {
        hasAuthRouted: false,
        shouldRoute: false,
        fireauth: null
    }

    triggerAuthRouter = () => {
        const fromState = this.props.location.state && this.props.location.state.from.pathname
            ? this.props.location.state.from.pathname
            : null
        return <Navigate to={routes.router.path} state={{ from: { pathname: fromState } }} />
    }

    componentDidMount = () => {
        const { dispatch } = this.props
        const fireauth = getAuth(this.props.firebaseApp)
        this.setState({ fireauth })
        onAuthStateChanged(fireauth, firebaseUser => {
            if (firebaseUser) {
                if (firebaseUser.isAnonymous) {
                    dispatch(saveAnonymousAuth(fireauth, firebaseUser))
                } else {
                    dispatch(saveAuth(firebaseUser.toJSON()))
                    const pathname = this.props.location.pathname
                    if (ROUTER_PAGES.indexOf(pathname) !== -1 ||
                        pathname.indexOf('/app/') !== -1 ||
                        pathname.indexOf('/admin/') !== -1
                    ) {
                        this.setState({ hasAuthRouted: true })
                        this.setState({ shouldRoute: true })
                    }
                }
            } else {
                dispatch(removeAuth())
                this.setState({ hasAuthRouted: false })
            }
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.state.hasAuthRouted && !isEmpty(this.props.auth)) {
            if (prevProps.location.pathname !== this.props.location.pathname) {
                const pathname = this.props.location.pathname
                if (ROUTER_PAGES.indexOf(pathname) !== -1 ||
                    pathname.indexOf('/app/') !== -1 ||
                    pathname.indexOf('/admin/') !== -1
                ) {
                    this.setState({ hasAuthRouted: true })
                    this.setState({ shouldRoute: true })
                }
            }
        }
        if (this.state.hasAuthRouted && this.state.shouldRoute) {
            this.setState({ shouldRoute: false })
        }
    }

    render = () => {
        const { auth, user } = this.props

        if (this.state.shouldRoute) {
            return this.triggerAuthRouter()
        }

        return (
            <>
                <Routes>
                    <Route path={routes.appIndex.path} element={<AppRoute auth={auth} location={this.props.location}><AppIndex /></AppRoute>} />
                    <Route path={`${routes.adminIndex.path}*`} element={<AdminRoute auth={auth} user={user} location={this.props.location}><AdminIndex /></AdminRoute>} />
                    <Route path={`${routes.chefPortalIndex.path}*`} element={<ChefRoute auth={auth} user={user} location={this.props.location}><ChefPortalIndex /></ChefRoute>} />
                    <Route path={routes.router.path} element={<AppRoute auth={auth} location={this.props.location}><AuthRouter /></AppRoute>} />
                    <Route path={`${routes.sharedIndex.path}*`} element={<SharedIndex />} />
                    <Route path={`${routes.previewIndex.path}*`} element={<PreviewIndex />} />
                    <Route path="*" element={<PublicIndex fireauth={this.state.fireauth} />} />
                </Routes>
                <DevTools />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        user: state.user
    }
}

export default withRouterProps(connect(mapStateToProps)(App))
