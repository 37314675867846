import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { apiResourceEndpoint, routes } from '../../../constants/routes'
import { fetchApiDataIfNeeded } from '../../../actions/api'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const mapStateToProps = (state) => {
    return {
        primaryAreas: state.chefServiceAreas.filter(area => area.chefId === state.user?.apiUserId && area.availabilityType === 'PRIMARY'),
        temporaryAreas: state.chefServiceAreas.filter(area => area.chefId === state.user?.apiUserId && area.availabilityType === 'TEMPORARY'),
        menus: state.chefMenus.filter(m => m.chefId === state.user?.apiUserId),
        photos: state.chefPhotos.filter(p => p.chefId === state.user?.apiUserId),
        profilePhoto: state.chefPhotos.find(cp => cp.isProfile === true)
    }
}

const ChefPortalOverview = ({ dispatch, chef, primaryAreas, temporaryAreas, handleDelete, menus, photos, profilePhoto }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs/chef-service-areas', 'LIST')))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs/chef-menus', 'LIST')))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs/chef-photos', 'LIST')))
    }, [dispatch])

    const renderChefProfileRequirement = () => {
        return (
            <div className="overview-task">
                <div className="icon-wrapper">
                    <FontAwesomeIcon icon="user-ninja" />
                </div>
                <div className="label-wrapper">
                    <h4>
                        <Link to={routes.chefPortalProfile.path}>Profile</Link>
                    </h4>
                </div>
                <div className="button-wrapper">
                    {
                        chef.shortBio && profilePhoto
                            ? <span className="complete">complete <FontAwesomeIcon icon="circle-check" /></span>
                            : <Link className="action-btn" to={routes.chefPortalProfile.path}>Continue</Link>
                    }
                </div>
            </div>
        )
    }

    // const renderChefDocumentsRequirement = () => {
    //     return (
    //         <div className="overview-task">
    //             <div className="icon-wrapper">
    //                 <FontAwesomeIcon icon="file-alt" />
    //             </div>
    //             <div className="label-wrapper">
    //                 <h4>
    //                     <Link to={routes.chefPortalDocuments.path}>Documents</Link>
    //                 </h4>
    //             </div>
    //             <div className="button-wrapper">
    //                 {
    //                     chef.shortBio && chef.profilePhoto
    //                         ? <span className="complete">complete <FontAwesomeIcon icon="circle-check" /></span>
    //                         : <Link className="action-btn" to={routes.chefPortalDocuments.path}>Continue</Link>
    //                 }
    //             </div>
    //         </div>
    //     )
    // }

    const renderChefPhotosOption = () => {
        return (
            <div className="overview-task">
                <div className="icon-wrapper">
                    <FontAwesomeIcon icon="images" />
                </div>
                <div className="label-wrapper">
                    <h4>
                        <Link to={routes.chefPortalPhotos.path}>Photos</Link>
                    </h4>
                </div>
                <div className="button-wrapper">
                    {
                        photos.length > 0
                            ? <span className="complete">complete <FontAwesomeIcon icon="circle-check" /></span>
                            : <Link className="action-btn" to={routes.chefPortalPhotos.path}>Continue</Link>
                    }
                </div>
            </div>
        )
    }

    const renderChefMenusOption = () => {
        return (
            <div className="overview-task">
                <div className="icon-label-wrapper">
                    <div className="icon-wrapper">
                        <FontAwesomeIcon icon="bowl-rice" />
                    </div>
                    <div className="label-wrapper">
                        <h4>
                            <Link to={routes.chefPortalMenusIndex.path}>Menus</Link>
                        </h4>
                    </div>
                </div>
                <div className="button-wrapper">
                    {
                        menus.length > 0
                            ? <span className="complete">complete <FontAwesomeIcon icon="circle-check" /></span>
                            : <Link className="action-btn" to={routes.chefPortalMenusIndex.path}>Continue</Link>
                    }
                </div>
            </div>
        )
    }

    const renderChefAvailabilityOption = () => {
        return (
            <div className="overview-task">
                <div className="icon-label-wrapper">
                    <div className="icon-wrapper">
                        <FontAwesomeIcon icon={['far', 'calendar-alt']} />
                    </div>
                    <div className="label-wrapper">
                        <h4>
                            <Link to={routes.chefPortalAvailability.path}>Availability</Link>
                        </h4>
                    </div>
                </div>
                <div className="button-wrapper">
                    {
                        primaryAreas.length > 0
                            ? <span className="complete">complete <FontAwesomeIcon icon="circle-check" /></span>
                            : <Link className="action-btn" to={routes.chefPortalAvailability.path}>Continue</Link>
                    }
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="chef-block header" style={{ background: 'url(/img/bg/gold-table-cloth-bg.jpg)', backgroundSize: 'no-cover', backgroundRepeat: 'no-repeat' }}>
                <h2>welcome to tivity</h2>
                <p>Here you can set up your profile, upload photos, create menus and upload documents to get started working with us!</p>
            </div>
            <h3>Required</h3>
            <div className="chef-block required">
                <div className="content required">
                    <FontAwesomeIcon icon="hand-sparkles" />
                    <p>Complete all required sections below to begin working with tivity</p>
                </div>
            </div>
            {renderChefProfileRequirement()}
            {/* {renderChefDocumentsRequirement()} */}

            <h3>Recommended</h3>
            <div className="chef-block required">
                <div className="content required">
                    <FontAwesomeIcon icon="hand-sparkles" />
                    <p>Make your profile stand out by adding additional photos and menus.</p>
                </div>
            </div>
            {renderChefPhotosOption()}
            {renderChefMenusOption()}
            {renderChefAvailabilityOption()}
        </>
    )
}

export default connect(mapStateToProps)(ChefPortalOverview)
