import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { apiResourceEndpoint, buildRoutePath, routes } from '../../../../../constants/routes'
import { fetchApiDataIfNeeded } from '../../../../../actions/api'

import { PaginationWrapper } from 'shs-pagination'

const mapStateToProps = (state) => {
    const paginator = state.paginator.chefMenus
    let menus = state.chefMenus.filter(m => m.chefId === state.user?.apiUserId)
    if (paginator) {
        menus = menus
            .filter(field => paginator.itemIds.indexOf(field.id) !== -1)
            .sort((a, b) => {
                if (paginator.itemIds.indexOf(a.id) > paginator.itemIds.indexOf(b.id)) {
                    return 1
                }
                return -1
            })
    }
    return {
        menus,
        paginator
    }
}

const ListMenus = ({ dispatch, menus, paginator }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs/chef-menus', 'LIST')))
    }, [dispatch])

    const renderMenuRow = (menu, index) => {
        return (
            <tr key={menu.id}>
                <td className="index">{index}</td>
                <td>
                    <Link to={buildRoutePath(routes.chefPortalMenusView.path, { menuId: menu.id })} className="main">
                        {menu.title}
                    </Link>
                </td>
            </tr>
        )
    }

    const handlePaginationUpdate = (paginationParams) => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs/chef-menus', 'LIST'), paginationParams))
    }

    const renderMenusList = () => {
        if (!paginator) {
            return null
        }
        let allMenus = []
        menus.forEach((menu, idx) => {
            let index = idx + ((paginator.currentPage - 1) * paginator.perPage) + 1
            allMenus.push(renderMenuRow(menu, index))
        })
        return (
            <PaginationWrapper
                paginator={paginator}
                controls={{ search: { enabled: true, collapsed: true } }}
                callbackFX={handlePaginationUpdate}
                countOptions={[2, 5, 10, 20]}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Menu Title</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allMenus}
                    </tbody>
                </table>
            </PaginationWrapper>
        )
    }

    return (
        <>
            <Link to={routes.chefPortalMenusCreate.path}>+ Create Menu</Link>
            {renderMenusList()}
        </>
    )
}

export default connect(mapStateToProps)(ListMenus)
