import { getAuth, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth'
import { actionCodeSettings } from '../firebase/firebase'
import { formStates } from '../constants/helper-states'
import { upsertForm } from './form'
import { removeAuth } from '../reducers/auth'

const killState = () => {
    return {
        type: `KILL_STATE`
    }
}

export const saveAnonymousAuth = (auth) => {
    return {
        type: `SAVE_ANONYMOUS_AUTH`,
        auth
    }
}

// export const removeAuth = () => {
//     return {
//         type: `REMOVE_AUTH`
//     }
// }

const saveAuthHandlerConfig = (code, action, email) => {
    return {
        type: `SAVE_AUTH_HANDLER`,
        code,
        action,
        email
    }
}

const saveAuthHandlerError = () => {
    return {
        type: 'AUTH_HANDLER_ERROR'
    }
}

export const clearAuthCode = () => {
    return {
        type: `REMOVE_AUTH_HANDLER`
    }
}

export const registerWithEmailAndPass = (fireauth, email, password, formId) => {
    return dispatch => {
        createUserWithEmailAndPassword(fireauth, email, password)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const signInWithEmailAndPass = (fireauth, email, password, formId) => {
    // console.log(fireauth)
    return dispatch => {
        signInWithEmailAndPassword(fireauth, email, password)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const generatePasswordResetLink = (fireauth, email, formId) => {
    return dispatch => {
        fireauth.sendPasswordResetEmail(email, actionCodeSettings)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                console.log(err)
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const resetPasswordWithCode = (fireauth, code, email, password, formId) => {
    return dispatch => {
        return fireauth.confirmPasswordReset(code, password)
            .then((response) => {
                fireauth.signInWithEmailAndPassword(email, password)
                    .then(() => {
                        dispatch(upsertForm(formId, formStates.SUCCESS))
                    })
                    .catch(err => {
                        console.log('in here with an error?')
                    })
            })
            .catch(err => {
                console.log('in here?')
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const updateAuthPassword = (fireauth, formData, formId) => {
    return dispatch => {
        if (formData.newPassword !== formData.confirmPassword) {
            dispatch(upsertForm(formId, formStates.ERROR, 'New passwords do not match.'))
            return false
        }
        const fireUser = fireauth.currentUser
        const credentials = fireauth.EmailAuthProvider.credential(fireUser.email, formData.existingPassword)
        return fireUser.reauthenticateWithCredential(credentials)
            .then(() => {
                fireUser.updatePassword(formData.newPassword)
                    .then(() => {
                        dispatch(upsertForm(formId, formStates.SUCCESS))
                    })
                    .catch(err => {
                        dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
                    })
            })
            .catch(err => {
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const handleFirebaseAuthCode = (fireauth, code, action) => {
    return dispatch => {
        fireauth.verifyPasswordResetCode(code)
            .then(email => {
                dispatch(saveAuthHandlerConfig(code, action, email))
            })
            .catch(err => {
                dispatch(saveAuthHandlerError())
                console.log('got an error...', err)
            })
    }
}

// export const handleFirebaseAuth = (firebaseUser) => {
//     return dispatch => {
//         dispatch(saveAuth(firebaseUser))
//     }
// }

export const handleSignOut = () => {
    return dispatch => {
        const auth = getAuth()
        signOut(auth)
            .then(() => {
                dispatch(removeAuth())
                dispatch(killState())
            })
            .catch(err => {
                console.log(err)
            })
    }
}
