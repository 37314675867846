import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { withRouterProps } from '../utils/with-router-props'

// import { removeAuth } from '../../reducers/auth'
import { handleSignOut } from '../../actions/auth'
import { routes } from '../../constants/routes'


class Logout extends React.Component {
    state = {
        readyToRoute: false
    }

    componentDidMount = () => {
        this.props.dispatch(handleSignOut())
        this.setState({ readyToRoute: true })
    }

    render = () => {
        if (this.state.readyToRoute) {
            return <Navigate to={routes.authLogin.path} />
        }
        return (
            <div>Logging out...</div>
        )
    }
}

export default withRouterProps(connect()(Logout))
