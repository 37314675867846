import React from 'react'

import EditableContent from '../../../utils/editable-content'

const MenuItemTitle = ({ item }) => {
    return (
        <div className="menu-item-title-block">
            <EditableContent
                dataObj={item}
                display={item.titleOverride ? item.titleOverride : item._computed.foodItemTitle}
                field="titleOverride"
                endpoint="admin/menu-items"
                className="item-title"
                title="Menu Item Title" />
            <EditableContent
                dataObj={item}
                display={item.descriptionOverride ? item.descriptionOverride : item._computed.foodItemDescription}
                field="descriptionOverride"
                endpoint="admin/menu-items"
                className="item-description"
                title="Menu Item Description" />
        </div>
    )
}

export default MenuItemTitle
