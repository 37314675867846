import React from 'react'

import ChefPrimaryFields from './primary-fields'
import ChefContactInfo from './contact-info'
import ChefDetails from './details'

const AdminChefProfile = ({ chef }) => {
    return (
        <>
            <h2>Chef Profile</h2>
            <ChefPrimaryFields chef={chef} />
            <ChefContactInfo chef={chef} />
            <ChefDetails chef={chef} />
        </>
    )
}

export default AdminChefProfile
