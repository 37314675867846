import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'
import { fetchApiDataIfNeeded } from '../../../actions/api'
import { routes, apiResourceEndpoint, buildRoutePath } from '../../../constants/routes'
import '../../../assets/scss/admin.scss'
import ChefDetails from './details'
// import ChefPrimaryFields from './primary-fields'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openDrawer } from '../../../reducers/ui-drawer'
import * as MASKS from '../../utils/input-masks'
import { Link } from 'react-router-dom'

const mapStateToProps = (state) => {
    return {
        details: state.chefDetails.filter(c => c.chefId === state.user?.apiUserId),
        profilePhoto: state.chefPhotos.find(cp => cp.isProfile === true),
        uiDrawer: state.uiDrawer,
        cuisines: state.apiCuisines.slice().sort((a, b) => {
            if (a.name > b.name) {
                return 1
            }
            return -1
        }),
        serviceStyles: state.apiServiceStyles.slice().sort((a, b) => {
            if (a.name > b.name) {
                return 1
            }
            return -1
        })
    }
}

const ChefPortalProfile = ({ chef, details, dispatch, profilePhoto, cuisines, serviceStyles }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs/chef-details', 'LIST')))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs/chef-photos', 'LIST')))
    }, [dispatch])

    if (!details) {
        return (
            <h1>Loading...</h1>
        )
    }

    const renderCuisines = () => {
        let activeCuisines = cuisines.filter(cuisine => chef._computed.cuisineId.indexOf(cuisine.id) !== -1)
        let activeRendered = []
        activeCuisines.forEach(cuisine => {
            activeRendered.push(
                <div className="allergen active" key={cuisine.id}>
                    {cuisine.name}
                </div>
            )
        })
        return (
            <div className="specialty">
                <h3 className="clickable" onClick={() => dispatch(openDrawer({ category: 'EDIT_CUISINES' })) }>Cuisines</h3>
                {activeRendered}
            </div>
        )
    }

    const renderServiceStyles = () => {
        let activeSS = serviceStyles.filter(ss => chef._computed.serviceStyleId.indexOf(ss.id) !== -1)
        let activeRendered = []
        activeSS.forEach(ss => {
            activeRendered.push(
                <div className="allergen active" key={ss.id}>
                    {ss.name}
                </div>
            )
        })
        return (
            <div className="specialty">
                <h3 className="clickable" onClick={() => dispatch(openDrawer({ category: 'EDIT_SERVICE' }))}>Service Styles</h3>
                {activeRendered}
            </div>
        )
    }

    return (
        <>
            <div className="content-box profile">
                <div className="bio-content">
                    <div className="name-header">
                        <div className="name-display">
                            <h1>{chef.displayName}</h1>
                            <span className="edit-profile" onClick={() => dispatch(openDrawer({ category: 'EDIT_MAIN_PROFILE' }))}>
                                <FontAwesomeIcon icon="pen" />
                            </span>
                        </div>
                        <div className="pronouns">
                            {chef.pronouns || null}
                        </div>
                    </div>
                    <div className="primary-contact">
                        <h2>Contact</h2>
                        <div className="contact-info">
                            <a href={`mailto:${chef.email}`}>{chef.email}</a>
                        </div>
                        <div className="contact-info">
                            <a href={`tel:${chef.phone}`}>{MASKS.phoneMask.resolve(chef.phone)}</a>
                        </div>
                    </div>
                    <div className="short-bio">
                        <div className="header-with-edit">
                            <h2>Bio</h2>
                            <span className="edit-icon" onClick={() => dispatch(openDrawer({ category: 'EDIT_BIO' }))}>
                                <FontAwesomeIcon icon="pen" />
                            </span>
                        </div>
                        <p className="p-wrap">{chef.shortBio || 'N/A'}</p>
                    </div>
                </div>
                <div className="bio-sidebar">
                    <div className="profile-photo">
                        { profilePhoto
                            ? <span> <img
                                src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`api-photos/${profilePhoto.photoId}`, 'LIST').url + '?style=md-square'}
                                alt={profilePhoto.overrideAlt ? profilePhoto.overrideAlt : profilePhoto._computed.photoAlt} />
                            </span>
                            : <span className="no-photo">
                                <FontAwesomeIcon icon="hand-sparkles" />
                                <span onClick={() => dispatch(openDrawer({ category: 'PROFILE_PHOTO' }))}>Headshot Required</span>
                            </span>
                        }
                    </div>
                    <div className="specialties">
                        {renderCuisines()}
                        {renderServiceStyles()}
                    </div>
                    <Link to={buildRoutePath(routes.chefPreview.path, { chefId: chef.id })} className="btn" target="_blank">
                        Preview Profile
                    </Link>
                </div>
            </div>
            <div className="content-box">
                {<ChefDetails chef={chef} />}
            </div>
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(ChefPortalProfile))
